.logo {
    height: 180px;
  }
  
  .nav-link {
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  
  .navbar {
    background-color: #C73B33;
  }
  
  .navbar .nav-tabs {
    display: flex;
  }
  
  .nav-tabs .nav-link {
    margin-left: 20px;
  }
  